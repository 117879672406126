import React, { useState } from 'react'
import LinkButton from '../../LinkButton/LinkButton'
import './CategoryInfo.scss'

interface Props {
  visible: React.ReactNode
  hidden: React.ReactNode
  blogLink?: string
  blogLabel?: string
}

const ReadMoreLessSections: React.FC<Props> = ({
  visible,
  hidden,
  blogLink,
  blogLabel,
}) => {
  const [expand, setExpand] = useState(false)
  const toggle = () => {
    setExpand(!expand)
  }

  return (
    <div>
      <div className="visible">{visible}</div>
      {!expand && (
        <button type="button" onClick={toggle}>
          Read More
        </button>
      )}

      <div className={`hidden ${expand ? '' : 'd-none'}`}>{hidden}</div>

      {expand && (
        <button type="button" onClick={toggle}>
          Read Less
        </button>
      )}
      <div>
        {blogLink ? (
          <LinkButton
            className="popBtn inline-btn"
            to={blogLink}
            color="white"
            background="transparent"
          >
            {blogLabel || 'Learn to Play'}
          </LinkButton>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default ReadMoreLessSections
