import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Swipeable } from 'react-swipeable'

import './Features.scss'
import Container from 'react-bootstrap/esm/Container'
import { Carousel } from 'react-bootstrap'
import Title from '../../Title/Title'
import Feature, { FeatureInterface } from './Feature'
import { formatForBigQuery, isMobile } from '../../../formatForBigQuery'

const Features: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "features-icons/1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
      img2: file(relativePath: { eq: "features-icons/2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
      img3: file(relativePath: { eq: "features-icons/3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
      img4: file(relativePath: { eq: "features-icons/4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
      img5: file(relativePath: { eq: "features-icons/5.png" }) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
      img6: file(relativePath: { eq: "features-icons/6.png" }) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
    }
  `)

  const features: FeatureInterface[] = [
    {
      src: data.img1.childImageSharp.gatsbyImageData,
      title: 'Play Favourite Rummy Game',
      titleColor: '#37d6c2',
      text: 'Say hi to your friends over your favourite games',
      lists: [
        'Play Rummy 24x7',
        'No Wait Time on Tables',
        'Multi-table Gameplay',
      ],
      width: '148px',
    },
    {
      src: data.img2.childImageSharp.gatsbyImageData,
      title: '100% Legal & Secure',
      titleColor: '#2270DA',
      text: 'Play games starting at ₹1. Host a game with a buy-in of your choice',
      lists: [
        'Secure Payment Transaction',
        'RNG Certification',
        'Member of Federation (AIGF)',
      ],
      width: '148px',
    },
    {
      src: data.img3.childImageSharp.gatsbyImageData,
      title: 'Premium Customer Support',
      titleColor: '#FFac08',
      text: 'Get all your gaming problems resolved in minutes',
      lists: [
        '24X7 Chat Support in English & Hindi',
        'Dedicated Key Account Managers for VIP Players',
        '24X7 Call Back for VIP Players',
      ],
      width: '80px',
    },
    {
      src: data.img4.childImageSharp.gatsbyImageData,
      title: 'Responsible Gaming',
      titleColor: '#Fc1858',
      text: 'Get all your gaming problems resolved in minutes',
      lists: [
        'Practice for free using Bonus',
        'KYC Verified Real Players',
        'No Bot Certification',
      ],
      width: '80px',
    },
    {
      src: data.img5.childImageSharp.gatsbyImageData,
      title: 'Great Offers & Rewards',
      titleColor: '#fff508',
      text: 'Get all your gaming problems resolved in minutes',
      lists: [
        'Leaderboards Starting Every 2 Hours',
        'Guaranteed Rewards on completing Tasks',
        'Rewards in Deposit Wallet!',
      ],
      width: '80px',
    },
    {
      src: data.img6.childImageSharp.gatsbyImageData,
      title: 'Fastest Withdrawals',
      titleColor: '#7aa208',
      text: 'Get all your gaming problems resolved in minutes',
      lists: [
        'Instant Withdrawal 24x7',
        'Supports All Payment Modes',
        'Redeem Your Winnings at Any Time',
      ],
      width: '80px',
    },
  ]

  const [visibleFeature, setVisibleFeature] = useState(0)

  return (
    <div id="features">
      <Container>
        <Title color="white">Rummy Features</Title>

        <Swipeable
          onSwipedLeft={() => {
            setVisibleFeature((visibleFeature + 1) % features.length)
          }}
          onSwipedRight={() => {
            const len = features.length
            setVisibleFeature((visibleFeature - 1 + len) % len)
          }}
          preventDefaultTouchmoveEvent
          className="swipe-div d-block d-lg-none"
        >
          <Carousel
            controls={false}
            indicators={false}
            interval={1000 * 1000}
            onSlide={i => {
              setVisibleFeature(i)
            }}
            activeIndex={visibleFeature}
          >
            {features.map(f => {
              return (
                <Carousel.Item key={f.title}>
                  <Feature feature={f} />
                </Carousel.Item>
              )
            })}
          </Carousel>

          <div className="number-list">
            {features.map((f, i) => {
              return (
                <button
                  key={f.title}
                  className={`f${i + 1}${
                    visibleFeature === i ? '' : ' disabled'
                  }`}
                  onClick={(): void => {
                    setVisibleFeature(i)

                    try {
                      window?.rudderanalytics?.track?.(
                        'smartFeatureButtonClicked',
                        {
                          event_params: formatForBigQuery({
                            device: isMobile() ? 'Mobile' : 'Desktop',
                            button_id: i + 1,
                          }),
                        }
                      )
                    } catch (er) {
                      console.error(er)
                    }
                  }}
                  type="button"
                  style={{
                    color: f.titleColor,
                    borderBottom: `3px ${f.titleColor} solid`,
                    borderRadius: '2px',
                  }}
                >
                  {i + 1}
                </button>
              )
            })}
          </div>
        </Swipeable>

        <div className="d-none d-lg-block">
          <div className="desktop-grid">
            {features.map(f => {
              return <Feature key={f.title} feature={f} />
            })}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Features
