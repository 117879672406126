import React, { useState, useEffect } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { isAndroid, isIOS } from '../../../../formatForBigQuery'
import useRandomNumber from '../../../../hooks/useRandomNumber'

interface Props {
  rummyLink?: string
  variantImage?: any
  name?: string
}
const RummyVariant: React.FC<Props> = ({ rummyLink, variantImage, name }) => {
  const [platform, setPlatform] = useState('web') // default set to web
  const count = useRandomNumber(4000, 10000)
  useEffect(() => {
    if (isAndroid()) {
      setPlatform('android')
    }
    if (isIOS()) {
      setPlatform('ios')
    }
  }, [platform])

  return (
    <a
      href={rummyLink}
      onClick={() => {
        window?.trackGLDownloadEvent?.()
      }}
      className="variant_box"
    >
      <div className="variant_item">
        <div className="variant_icon">
          <GatsbyImage
            image={variantImage}
            loading="eager"
            alt="Mega poker &amp; rummy games"
            imgStyle={{ objectFit: 'contain' }}
            className="img-landing non-carousel"
          />
        </div>
        <p>{name}</p>
        <span>
          {count
            .toFixed(2)
            .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1')
            .replace('.00', '')}{' '}
          Online
        </span>
      </div>
    </a>
  )
}

export default RummyVariant
