import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import RummyPage from '../components/Games/Cards/Rummy/Rummy'
import { formatForBigQuery, isMobile } from '../formatForBigQuery'
import {
  generateFaqSchema,
  generateReviewSchema,
  generateRummyTrailerSchema,
  generateRummyTutorialsVideoSchema,
} from '../lib/generate-schema'
import useTestimonials from '../components/Testimonials/useTestimonials'
import { Faq } from '../components/FAQs/faqs-list-interface'

declare global {
  interface Window {
    rudderanalytics: {
      track: (
        event: string,
        properties: Record<string, unknown>,
        callback?: () => void
      ) => void
    }
  }
}

const Rummy: React.FC = () => {
  const [rummyFaqsSt, setRummyFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../components/FAQs/faqs-list').then(({ rummyFaqs }) =>
      setRummyFaqsSt(rummyFaqs)
    )
  }, [])

  useEffect(() => {
    try {
      window?.rudderanalytics?.track?.('GamePageViewed', {
        event_params: formatForBigQuery({
          game_name: 'rummy',
          device: isMobile() ? 'Mobile' : 'Desktop',
        }),
      })
    } catch (er) {
      console.error(er)
    }
  }, [])

  const { rummyTestimonials } = useTestimonials()

  return (
    <Layout>
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Play Rummy Online In India For Real Cash | Win Upto 1 Cr | Mega"
        description="Play Rummy on Mega, India's hottest real money gaming platform. Win exciting cash prizes - up to ₹500,000 a week. Experience classic Indian real cash Rummy now."
        reviewSchema={generateReviewSchema(rummyTestimonials)}
        faqSchema={generateFaqSchema(rummyFaqsSt)}
        videoSchema={[
          generateRummyTrailerSchema(),
          ...generateRummyTutorialsVideoSchema(),
        ]}
      />
      <RummyPage />
    </Layout>
  )
}

export default Rummy
