import React, { ReactNode } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Container from 'react-bootstrap/esm/Container'
// import { Swipeable } from 'react-swipeable'
// eslint-disable-next-line
// @ts-ignore
import ReadMoreAndLess from 'react-read-more-less'

import './PaymentPartners.scss'
import Title from '../../Title/Title'
import useShowAll from '../../../hooks/useShowAll'
// import scrollToSection from '../SwipeManager/scroll-to-section'

interface Props {
  title?: ReactNode
}

const PaymentPartners: React.FC<Props> = ({ title }) => {
  const showAll = useShowAll()
  const text = `
  Transfer your winnings in just a minute through UPI, or to your Paytm wallet, or directly to your bank account. Get faster-than-industry cash-outs via safe and secure payment channels, only on Mega!
  `

  return (
    // <Swipeable
    //   onSwipedUp={() => {
    //     scrollToSection('features')
    //   }}
    //   preventDefaultTouchmoveEvent
    // >
    <div id="payment-partners">
      <Container>
        <Title color="white" className="p-title">
          {title || (
            <>
              <div>Withdraw your money</div>
              <div>
                in just <span className="secs">60 seconds</span>
              </div>
            </>
          )}
        </Title>

        <div className="partners">
          <div className="p paytm">
            <StaticImage
              src="../../../images/paytm.png"
              alt="paytm"
              width={800}
            />
          </div>
          <div className="p phonepe">
            <StaticImage
              src="../../../images/phonepe.png"
              alt="phonepe"
              width={800}
            />
          </div>
          <div className="p amazonpay">
            <StaticImage
              src="../../../images/gpay.png"
              alt="amazon pay"
              width={800}
            />
          </div>
          <div className="p bank-transfer">
            <StaticImage
              src="../../../images/bank-transfer.png"
              alt="bank transfer"
              width={800}
            />
          </div>
        </div>

        <div className="short-text-wrap">
          {showAll ? (
            <div className="short-text">{text}</div>
          ) : (
            <ReadMoreAndLess
              className="read-more-content"
              charLimit={100}
              readMoreText=" Read more"
              readLessText=" Read less"
            >
              {text}
            </ReadMoreAndLess>
          )}
        </div>
      </Container>
    </div>
    // </Swipeable>
  )
}

export default PaymentPartners
