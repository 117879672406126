import React from 'react'
import HomeTestimonials from '../../../Index/Testimonials/HomeTestimonials'
import { TestimonialProps } from '../../../Testimonials/Testimonial'

interface Props {
  testimonials: TestimonialProps[]
}

const GameTestimonials: React.FC<Props> = ({ testimonials }) => {
  return (
    <div id="game-testimonials">
      <HomeTestimonials color="white" testimonials={testimonials} />
    </div>
  )
}

export default GameTestimonials
