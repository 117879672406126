import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import './CertifiedCard.scss'

export interface Certified {
  src: IGatsbyImageData
  text1: string
  text2: string
}

interface Props {
  certified: Certified
  textColor?: string
}

const CertifiedCard: React.FC<Props> = ({ certified, textColor = '#000' }) => {
  return (
    <div className="certified-card">
      <div className="certification-image">
        <GatsbyImage
          image={certified.src}
          loading="eager"
          alt={certified.text1}
        />
      </div>
      <div className="text">
        <h3 style={{ color: textColor }}>
          {certified.text1} <br />
          {certified.text2}
        </h3>
      </div>
    </div>
  )
}

export default CertifiedCard
