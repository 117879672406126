import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import './Videos.scss'
import { Col, Container, Row } from 'react-bootstrap'
import FullscreenVideo from '../../../FullscreenVideo/FullscreenVideo'
import { VideoInfo } from '../../videos-list'

interface Props {
  videos: VideoInfo[]
  title?: React.ReactNode
  showTitle?: boolean
}

const Videos: React.FC<Props> = ({ videos, title, showTitle = true }) => {
  const data = useStaticQuery(graphql`
    {
      pokerVideo: file(relativePath: { eq: "poker-video.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      play: file(relativePath: { eq: "play-circle.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [showVideo, setShowVideo] = useState(false)
  const [videoLink, setVideoLink] = useState('')

  return (
    <Container>
      <Row>
        <Col className="videos">
          {showTitle && (
            <h2 className="text">
              {title || 'Learn the game in a flash with our easy tutorials'}
            </h2>
          )}

          <div className={`list s${videos.length}`}>
            {videos.map(v => {
              return (
                <div
                  className="video"
                  key={v.title}
                  role="button"
                  tabIndex={0}
                  onClick={(): void => {
                    setVideoLink(v.link)
                    setShowVideo(true)
                  }}
                  onKeyDown={(e): void => {
                    if (e.key === 'Enter') {
                      setVideoLink(v.link)
                      setShowVideo(true)
                    }
                  }}
                >
                  <div className="video-thumb-wrap">
                    <GatsbyImage
                      image={
                        v.thumbnail ||
                        data.pokerVideo.childImageSharp.gatsbyImageData
                      }
                      alt="poker video"
                      className="video-thumbnail"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                    <div className="play-div">
                      <button className="image-button" type="button">
                        <GatsbyImage
                          image={data.play.childImageSharp.gatsbyImageData}
                          alt="play"
                          className="play"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="video-text">
                    <div className="video-title">{v.title}</div>
                    <div className="length">{v.length}</div>
                  </div>
                </div>
              )
            })}
          </div>

          {showVideo && (
            <FullscreenVideo
              close={() => {
                setShowVideo(false)
              }}
              src={videoLink}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default Videos
