import { useEffect, useState } from 'react'

const useShowAll = (): boolean => {
  const [showAll, setShowAll] = useState(false)
  const BREAKPOINT = 500

  const update = () => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )

    if (vw >= BREAKPOINT) {
      setShowAll(true)
    } else {
      setShowAll(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', update)
    update()

    return () => {
      window.removeEventListener('resize', update)
    }
  }, [])

  return showAll
}

export default useShowAll
