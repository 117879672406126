import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReadMoreAndLess from 'react-read-more-less'

import './SafeAndCertified.scss'
import Container from 'react-bootstrap/esm/Container'
import Title from '../../Title/Title'
import CertifiedCard, { Certified } from './CertifiedCard'
import useShowAll from '../../../hooks/useShowAll'
import { PRIVACY_POLICY } from '../../internal-links'

interface Props {
  titleColor?: string
  bg?: string
  showDesc?: boolean
  showHeroImage?: boolean
  showLinks?: boolean
  textColor?: string
}

const SafeAndCertified: React.FC<Props> = ({
  titleColor = ' #2270da',
  bg = '#fff',
  showDesc = true,
  showHeroImage = true,
  showLinks = true,
  textColor = '#000',
}) => {
  const data = useStaticQuery(graphql`
    {
      safe: file(relativePath: { eq: "safe.png" }) {
        childImageSharp {
          gatsbyImageData(width: 315, layout: CONSTRAINED)
        }
      }
      icon1: file(relativePath: { eq: "safe/icon1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      icon2: file(relativePath: { eq: "safe/icon2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      icon4: file(relativePath: { eq: "safe/icon4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      icon5: file(relativePath: { eq: "safe/icon5.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      icon6: file(relativePath: { eq: "safe/icon6.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
    }
  `)

  const certifiedCards: Certified[] = [
    {
      src: data.icon1.childImageSharp.gatsbyImageData,
      text1: 'All India',
      text2: 'Gaming Federation',
    },
    {
      src: data.icon2.childImageSharp.gatsbyImageData,
      text1: 'RNG',
      text2: 'Certification',
    },
    {
      src: data.icon4.childImageSharp.gatsbyImageData,
      text1: 'Instant',
      text2: 'Withdrawal',
    },
    {
      src: data.icon5.childImageSharp.gatsbyImageData,
      text1: '5M+',
      text2: 'Downloads',
    },
    {
      src: data.icon6.childImageSharp.gatsbyImageData,
      text1: '10M+',
      text2: 'Money Won',
    },
  ]

  const showAll = useShowAll()
  const text = `
  We are a member of the All India Gaming Federation, and have built a
  platform where safety and security are of utmost importance. Our
  Random Number Generator and shuffle mechanics have been certified
  by iTech Labs, Australia, ensuring the safest gameplay of the
  highest standards. We have taken several measures to ensure that
  all games on the platform are truly skill-based games that are in
  accordance with Indian laws and regulations.
  `

  return (
    <div id="safe-and-certified" style={{ background: bg }}>
      <Container>
        {showHeroImage ? (
          <div className="image">
            <GatsbyImage
              image={data.safe.childImageSharp.gatsbyImageData}
              loading="eager"
              imgStyle={{ objectFit: 'contain' }}
              alt="safe and certified"
              className="image-item"
            />
          </div>
        ) : null}

        <Title color={titleColor} className="title">
          100% Safe and Certified
        </Title>

        <div className="cards">
          {certifiedCards.map(c => {
            return (
              <CertifiedCard
                key={c.text1}
                certified={c}
                textColor={textColor}
              />
            )
          })}
        </div>

        {showDesc ? (
          <div className="show-desc-wrap">
            {showAll ? (
              <div className="short-text">{text}</div>
            ) : (
              <ReadMoreAndLess
                className="read-more-content"
                charLimit={100}
                readMoreText=" Read more"
                readLessText=" Read less"
              >
                {text}
              </ReadMoreAndLess>
            )}
          </div>
        ) : null}

        {showLinks ? (
          <div className="links">
            <Link to="/certifications/">View Certificates</Link>
            <span>&nbsp;&nbsp; | &nbsp;&nbsp;</span>
            <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
          </div>
        ) : null}
      </Container>
    </div>
  )
}

export default SafeAndCertified
