import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Swipeable } from 'react-swipeable'

import { Carousel } from 'react-bootstrap'
import Feature, { FeatureInterface } from './Feature'
import { formatForBigQuery, isMobile } from '../../../formatForBigQuery'
import './MobRummyFeatures.scss'

const MobRummyFeatures: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "features-icons/1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, layout: CONSTRAINED)
        }
      }
      img2: file(relativePath: { eq: "features-icons/2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, layout: CONSTRAINED)
        }
      }
      img3: file(relativePath: { eq: "features-icons/3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, layout: CONSTRAINED)
        }
      }
      img4: file(relativePath: { eq: "features-icons/4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, layout: CONSTRAINED)
        }
      }
      img5: file(relativePath: { eq: "features-icons/5.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, layout: CONSTRAINED)
        }
      }
      img6: file(relativePath: { eq: "features-icons/6.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, layout: CONSTRAINED)
        }
      }
      img7: file(relativePath: { eq: "features-icons/7.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, layout: CONSTRAINED)
        }
      }
      img8: file(relativePath: { eq: "features-icons/8.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [visibleFeature, setVisibleFeature] = useState(0)

  const [mobileDevice, setMobileDevice] = useState(false)

  const features: FeatureInterface[] = [
    {
      src: data.img1.childImageSharp.gatsbyImageData,
      title: 'Play Favourite Rummy Game',
      titleColor: '#37d6c2',
      text: 'Say hi to your friends over your favourite games',
      lists: [
        'Play Rummy 24x7',
        'No Wait Time on Tables',
        'Multi-table Gameplay',
      ],
      width: '148px',
    },
    {
      src: data.img2.childImageSharp.gatsbyImageData,
      title: '100% Legal & Secure',
      titleColor: '#2270DA',
      text: 'Play games starting at ₹1. Host a game with a buy-in of your choice',
      lists: [
        'Secure Payment Transaction',
        'RNG Certification',
        'Member of Federation (AIGF)',
      ],
      width: '148px',
    },
    {
      src: data.img3.childImageSharp.gatsbyImageData,
      title: 'Premium Customer Support',
      titleColor: '#FFac08',
      text: 'Get all your gaming problems resolved in minutes',
      lists: [
        '24X7 Chat Support in English & Hindi',
        'Dedicated Key Account Managers for VIP Players',
        '24X7 Call Back for VIP Players',
      ],
      width: '80px',
    },
    {
      src: data.img4.childImageSharp.gatsbyImageData,
      title: 'Responsible Gaming',
      titleColor: '#Fc1858',
      text: 'Get all your gaming problems resolved in minutes',
      lists: [
        'Practice for free using Bonus',
        'KYC Verified Real Players',
        'No Bot Certification',
      ],
      width: '80px',
    },
    {
      src: data.img5.childImageSharp.gatsbyImageData,
      title: 'Great Offers & Rewards',
      titleColor: '#fff508',
      text: 'Get all your gaming problems resolved in minutes',
      lists: [
        'Leaderboards Starting Every 2 Hours',
        'Guaranteed Rewards on completing Tasks',
        'Rewards in Deposit Wallet!',
      ],
      width: '80px',
    },
    {
      src: data.img6.childImageSharp.gatsbyImageData,
      title: 'Fastest Withdrawals',
      titleColor: '#7aa208',
      text: 'Get all your gaming problems resolved in minutes',
      lists: [
        'Instant Withdrawal 24x7',
        'Supports All Payment Modes',
        'Redeem Your Winnings at Any Time',
      ],
      width: '80px',
    },
  ]
  const a = []
  const temp = features.map((v, i) => i + 1)
  const chunkSize = 2
  for (let i = 0; i < temp.length; i += chunkSize) {
    const chunk = temp.slice(i, i + chunkSize)
    a.push(chunk)
  }

  useEffect(() => {
    if (isMobile()) {
      setMobileDevice(true)
    }
  }, [])

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (mobileDevice) {
      interval = setInterval(() => {
        setVisibleFeature((visibleFeature + 1) % a.length)
      }, 5000)
    }

    return () => clearInterval(interval)
  }, [a.length, mobileDevice, visibleFeature])

  return (
    <div className="features-block-mob">
      <h4 className="feature-sub-title">Rummy Features</h4>
      <Swipeable
        onSwipedLeft={() => {
          setVisibleFeature((visibleFeature + 1) % a.length)
        }}
        onSwipedRight={() => {
          const len = a.length
          setVisibleFeature((visibleFeature - 1 + len) % len)
        }}
        preventDefaultTouchmoveEvent
        className="swipe-div d-block d-lg-none"
      >
        <Carousel
          controls={false}
          indicators={false}
          interval={1000 * 1000}
          onSlide={i => {
            setVisibleFeature(i)
          }}
          activeIndex={visibleFeature}
        >
          {a.map(f => {
            return (
              <Carousel.Item key={f.title}>
                <div className="feature_slide">
                  <Feature feature={features[f[0] - 1]} />
                  <Feature feature={features[f[1] - 1]} />
                </div>
              </Carousel.Item>
            )
          })}
        </Carousel>

        <div className="feature-nav">
          {a.map((f, i) => {
            return (
              <button
                key={f.title}
                className={`f${i + 1}${
                  visibleFeature === i ? '' : ' disabled'
                }`}
                onClick={(): void => {
                  setVisibleFeature(i)

                  try {
                    window?.rudderanalytics?.track?.(
                      'smartFeatureButtonClicked',
                      {
                        event_params: formatForBigQuery({
                          device: isMobile() ? 'Mobile' : 'Desktop',
                          button_id: i + 1,
                        }),
                      }
                    )
                  } catch (er) {
                    console.error(er)
                  }
                }}
                type="button"
              >
                {i + 1}
              </button>
            )
          })}
        </div>
      </Swipeable>
    </div>
  )
}

export default MobRummyFeatures
