import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './FullscreenVideo.scss'

interface Props {
  src?: string
  close(): void
}

const FullscreenVideo: React.FC<Props> = ({ close, src }) => {
  return (
    <div className="fullscreen-video">
      <div className="embed-container">
        <iframe
          title="video"
          src={
            src || 'https://player.vimeo.com/video/428035947?autoplay=1&rel=0'
            // 'https://player.vimeo.com/video/342926432?title=0&byline=0&portrait=0&autoplay=1&background=1'
          }
          width="640"
          height="1138"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      <div className="close-div">
        <button
          className="image-button"
          type="button"
          onClick={(e): void => {
            e.stopPropagation()
            e.preventDefault()
            close()
          }}
        >
          <StaticImage
            loading="eager"
            src="../../images/close-white.png"
            alt="close"
            className="close"
            width={72}
          />
        </button>
      </div>
    </div>
  )
}

export default FullscreenVideo
