import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './InfoCard.scss'

interface Props {
  customClass?: string
  children?: React.ReactNode
}

const InfoCard: React.FC<Props> = ({ customClass, children }) => {
  return (
    <Row>
      <Col>
        <div id="InfoCard" className={customClass}>
          {children}
          <div className="indicator" />
        </div>
      </Col>
    </Row>
  )
}

export default InfoCard
