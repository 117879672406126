import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import './Contests.scss'
import { Container } from 'react-bootstrap'
import Title from '../Title/Title'
import useRandomNumber from '../../hooks/useRandomNumber'
import { LATEST } from '../download-links'

interface Props {
  gameName?: string
}

const Contests: React.FC<Props> = ({ gameName }) => {
  const data = useStaticQuery(graphql`
    {
      contestsIcon: file(relativePath: { eq: "contests-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 330, layout: CONSTRAINED)
        }
      }
      infinity: file(relativePath: { eq: "infinity.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      users: file(relativePath: { eq: "users.png" }) {
        childImageSharp {
          gatsbyImageData(width: 12, layout: CONSTRAINED)
        }
      }
      cup: file(relativePath: { eq: "cup.png" }) {
        childImageSharp {
          gatsbyImageData(width: 12, layout: CONSTRAINED)
        }
      }
    }
  `)

  const c1 = useRandomNumber(1000, 2000)
  const c2 = useRandomNumber(1000, 2000)

  const gameContestValues: {
    [index: string]: Array<{ bb_pr: string; minBuyIn: string }>
  } = useMemo(
    () => ({
      Poker: [
        { bb_pr: '₹2 Big Blind', minBuyIn: '₹25 Min Buy-In' },
        { bb_pr: '₹5 Big Blind', minBuyIn: '₹50 Min Buy-In' },
      ],
      Rummy: [
        { bb_pr: '1 Point = ₹0.25', minBuyIn: '₹20 Min Buy-In' },
        { bb_pr: '1 Point = ₹1', minBuyIn: '₹80 Min Buy-In' },
      ],
      Carrom: [
        { bb_pr: '1 Point = ₹0.05', minBuyIn: '₹1 Min Buy-In' },
        { bb_pr: '1 Point = ₹1', minBuyIn: '₹10 Min Buy-In' },
      ],
      GoPool: [
        { bb_pr: '1 Point = ₹0.05', minBuyIn: '₹1 Min Buy-In' },
        { bb_pr: '1 Point = ₹1', minBuyIn: '₹10 Min Buy-In' },
      ],
      Warship: [
        { bb_pr: '1 Point = ₹0.10', minBuyIn: '₹2 Min Buy-In' },
        { bb_pr: '1 Point = ₹0.50', minBuyIn: '₹10 Min Buy-In' },
      ],
      ABCRummy: [
        { bb_pr: '1 Point = ₹0.01', minBuyIn: '₹1 Min Buy-In' },
        { bb_pr: '1 Point = ₹0.50', minBuyIn: '₹9 Min Buy-In' },
      ],
      DotsAndDash: [
        { bb_pr: '1 Point = ₹0.10', minBuyIn: '₹2 Min Buy-In' },
        { bb_pr: '1 Point = ₹0.50', minBuyIn: '₹10 Min Buy-In' },
      ],
      FruitKaat: [
        { bb_pr: '1 Point = ₹0.01', minBuyIn: '₹1 Min Buy-In' },
        { bb_pr: '1 Point = ₹0.50', minBuyIn: '₹10 Min Buy-In' },
      ],
      GKGuru: [{ bb_pr: '1 Point = ₹0.10', minBuyIn: '₹1 Min Buy-In' }],
      OneTwoThree: [{ bb_pr: '1 Point = ₹0.10', minBuyIn: '₹1 Min Buy-In' }],
      PicMe: [{ bb_pr: '1 Point = ₹0.10', minBuyIn: '₹1 Min Buy-In' }],
    }),
    []
  )

  return (
    <div id="contests">
      <Container>
        <GatsbyImage
          image={data.contestsIcon.childImageSharp.gatsbyImageData}
          alt="contests icon"
          className="contests-icon"
        />

        <Title color="black" className="c-title">
          Contests
        </Title>

        <div className="subtitle">Cash Tables</div>
        <div className="description">
          Join & leave as you please. For bigger winnings, choose higher{' '}
          {gameName === 'Poker' ? 'big blind ' : 'point '}
          tables.
        </div>

        {gameName && gameContestValues[gameName] ? (
          <div className="list">
            {gameContestValues[gameName].map(
              (ct: { bb_pr: string; minBuyIn: string }, i) => (
                <div
                  className="ct"
                  key={`${gameName}_${ct.bb_pr}_${ct.minBuyIn}`}
                >
                  <div className="icon-container">
                    <GatsbyImage
                      image={data.infinity.childImageSharp.gatsbyImageData}
                      alt="infinity"
                      className="table-icon"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </div>
                  <div className="point">{ct.bb_pr}</div>
                  <div />
                  <div className="buy-in">{ct.minBuyIn}</div>
                  <a
                    className="play"
                    target="_blank"
                    rel="noreferrer"
                    href={LATEST}
                    title="play now"
                  >
                    Play
                  </a>
                  <div />
                  <div className="available">
                    <div />
                    <span>Available 24x7</span>
                    <div className="dot">•</div>
                    <GatsbyImage
                      image={data.users.childImageSharp.gatsbyImageData}
                      alt="users"
                      className="users-icon"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                    <span>{i ? c1 : c2}</span>
                  </div>
                </div>
              )
            )}
          </div>
        ) : null}
      </Container>
    </div>
  )
}

export default Contests
