import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Swipeable } from 'react-swipeable'
import { Carousel } from 'react-bootstrap'
import MobRummyFeatures from '../../Features/MobRummyFeature'
import InstallHandler from './InstallHandler'
import RummyVariant from './RummyVariant'
import './MainBannerArea.scss'

interface Props {
  customClass?: string
  multipleBanner?: any
  apkLink?: any
}

const MainBannerArea: React.FC<Props> = ({
  customClass,
  multipleBanner,
  apkLink,
}) => {
  const data = useStaticQuery(graphql`
    {
      banner4: file(relativePath: { eq: "main-image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      bg4: file(relativePath: { eq: "carousel-home/4.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      point: file(relativePath: { eq: "game-banners/pointRummy.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      deal: file(relativePath: { eq: "game-banners/dealRummy.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      pool: file(relativePath: { eq: "game-banners/poolRummy101.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      pool2: file(relativePath: { eq: "game-banners/poolRummy201.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
    }
  `)

  const variant = [
    {
      id: 1,
      img: data.point.childImageSharp.gatsbyImageData,
      name: 'Point Rummy',
    },
    {
      id: 2,
      img: data.deal.childImageSharp.gatsbyImageData,
      name: 'Deals Rummy',
    },
    {
      id: 3,
      img: data.pool.childImageSharp.gatsbyImageData,
      name: '101 Pool Rummy',
    },
    {
      id: 4,
      img: data.pool2.childImageSharp.gatsbyImageData,
      name: '201 Pool Rummy',
    },
  ]

  return (
    <div id="DesktopMainScreen" className={customClass || ''}>
      <Container>
        <Row>
          <Col lg={6} className="cta">
            <div className="container">
              <div className="sms-form">
                <>
                  <h1 className="title white-text">
                    <span>Play Mega Rummy Cash Games</span>
                  </h1>
                  <div className="feature_area">
                    <MobRummyFeatures />
                  </div>
                  <div className="variant_area">
                    {variant.map((i?: any) => {
                      return (
                        <RummyVariant
                          key={i.id}
                          name={i.name}
                          variantImage={i.img}
                          rummyLink={apkLink}
                        />
                      )
                    })}
                  </div>
                  <p>Enjoy Mega Rummy PC Game</p>
                  <InstallHandler rummyLink={apkLink} />
                </>
              </div>
            </div>
          </Col>
          <Col className="image-wrapper">
            {multipleBanner ? (
              <Swipeable className="swipe-div">
                <Carousel
                  controls={false}
                  indicators={false}
                  interval={1000 * 3}
                >
                  {multipleBanner.map((i?: any) => {
                    return (
                      <Carousel.Item key={i.id}>
                        <div className="d-block d-lg-none">
                          <GatsbyImage
                            image={i.mob}
                            loading="eager"
                            alt="Mega poker &amp; rummy games"
                            imgStyle={{ objectFit: 'contain' }}
                            className="img-landing non-carousel"
                          />
                        </div>
                        <div className="d-none d-lg-block">
                          <GatsbyImage
                            image={i.desk}
                            loading="eager"
                            alt="Mega poker &amp; rummy games"
                            imgStyle={{ objectFit: 'contain' }}
                            className="img-landing non-carousel"
                          />
                        </div>
                      </Carousel.Item>
                    )
                  })}
                </Carousel>
              </Swipeable>
            ) : (
              <GatsbyImage
                image={data.banner4.childImageSharp.gatsbyImageData}
                loading="eager"
                alt="Mega poker &amp; rummy games"
                imgStyle={{ objectFit: 'contain' }}
                className="img-landing non-carousel"
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MainBannerArea
