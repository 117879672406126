import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import '../Poker/Poker.scss'
import { Container } from 'react-bootstrap'
import Videos from '../Poker/Videos'
import Contests from '../../Contests'
import GameTestimonials from '../Poker/GameTestimonials'
import RummyInfo from '../../CategoryAndGamesInfo/RummyInfo'
import HomeFAQs from '../../../Index/HomeFAQs/HomeFAQs'
import useVideos from '../../videos-list'
import { Faq } from '../../../FAQs/faqs-list-interface'
import useTestimonials from '../../../Testimonials/useTestimonials'
import InfoCard from '../../CategoryAndGamesInfo/InfoCard'
import PaymentPartners from '../../../Index/PaymentPartners/PaymentPartners'
import Features from '../../../Index/Features/Features'
import SafeAndCertified from '../../../Index/SafeAndCertified/SafeAndCertified'
import { LATEST } from '../../../download-links'
import MainBannerArea from '../../../Index/MainScreen/Desktop/MainBannerArea'

const Rummy: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "game-banners/Rummy.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobBanner: file(relativePath: { eq: "game-banners/mobBanner1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobBanner2: file(relativePath: { eq: "game-banners/mobBanner2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobBanner3: file(relativePath: { eq: "game-banners/mobBanner3.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      deskBanner: file(relativePath: { eq: "game-banners/deskBanner1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 840, layout: CONSTRAINED)
        }
      }
      deskBanner2: file(relativePath: { eq: "game-banners/deskBanner2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 840, layout: CONSTRAINED)
        }
      }
      deskBanner3: file(relativePath: { eq: "game-banners/deskBanner3.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 840, layout: CONSTRAINED)
        }
      }
    }
  `)
  const rummyBanner = [
    {
      id: 1,
      mob: data.mobBanner.childImageSharp.gatsbyImageData,
      desk: data.deskBanner.childImageSharp.gatsbyImageData,
      background: '#000000',
    },
    {
      id: 2,
      mob: data.mobBanner2.childImageSharp.gatsbyImageData,
      desk: data.deskBanner2.childImageSharp.gatsbyImageData,
      background: '#000000',
    },
    {
      id: 3,
      mob: data.mobBanner3.childImageSharp.gatsbyImageData,
      desk: data.deskBanner3.childImageSharp.gatsbyImageData,
      background: '#000000',
    },
  ]

  const [rummyFaqsSt, setRummyFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../../FAQs/faqs-list').then(({ rummyFaqs }) =>
      setRummyFaqsSt(rummyFaqs)
    )
  }, [])

  const { rummyTestimonials } = useTestimonials()
  const { rummyVideos } = useVideos()

  return (
    <div id="poker">
      <MainBannerArea
        multipleBanner={rummyBanner}
        customClass="home-banner"
        apkLink={LATEST}
      />
      <Videos videos={rummyVideos} />
      <Container>
        <InfoCard>
          <RummyInfo />
        </InfoCard>
      </Container>
      <Contests gameName="Rummy" />
      <PaymentPartners />
      <Features />
      <SafeAndCertified />
      <GameTestimonials testimonials={rummyTestimonials} />
      <div className="faqs-wrap">
        <HomeFAQs faqs={rummyFaqsSt} />
      </div>
    </div>
  )
}

export default Rummy
