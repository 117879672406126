import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
// eslint-disable-next-line
// @ts-ignore

import './Feature.scss'

export interface FeatureInterface {
  src: IGatsbyImageData
  title: string
  titleColor: string
  text: string
  width: string
  lists?: string[]
}

interface Props {
  feature: FeatureInterface
}

const Feature: React.FC<Props> = ({ feature }) => {
  return (
    <div className="feature">
      <div className="feature-image">
        <GatsbyImage
          image={feature.src}
          alt={feature.title}
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
      <div className="feature-texts">
        <h6>{feature.title}</h6>
        {feature.lists && (
          <ul className="feature-lists">
            {feature.lists.map(e => {
              return <li key={e}>{e}</li>
            })}
          </ul>
        )}
      </div>
    </div>
  )
}

export default Feature
