import { useStaticQuery, graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

export interface VideoInfo {
  title: string
  length: string
  link: string
  thumbnail?: IGatsbyImageData
}

export const rummyVideos: VideoInfo[] = [
  {
    title: 'How To Play Rummy 1/3',
    length: '1:00',
    link: 'https://player.vimeo.com/video/475776879?title=0&byline=0&portrait=0&autoplay=1&background=0',
  },
  {
    title: 'How To Play Rummy 2/3',
    length: '1:00',
    link: 'https://player.vimeo.com/video/475777098?title=0&byline=0&portrait=0&autoplay=1&background=0',
  },
  {
    title: 'How To Play Rummy 3/3',
    length: '1:08',
    link: 'https://player.vimeo.com/video/475777135?title=0&byline=0&portrait=0&autoplay=1&background=0',
  },
]

const useVideos = (): {
  pokerVideos: VideoInfo[]
  rummyVideos: VideoInfo[]
  carromVideos: VideoInfo[]
  poolVideos: VideoInfo[]
  gangsOfGamersVideos: VideoInfo[]
} => {
  const thumbnails = useStaticQuery(graphql`
    {
      r1: file(
        relativePath: {
          eq: "tutorial-thumbnails/rummy-tutorial-thumbnail-1.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 460, layout: CONSTRAINED)
        }
      }
      r2: file(
        relativePath: {
          eq: "tutorial-thumbnails/rummy-tutorial-thumbnail-2.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 460, layout: CONSTRAINED)
        }
      }
      r3: file(
        relativePath: {
          eq: "tutorial-thumbnails/rummy-tutorial-thumbnail-3.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 460, layout: CONSTRAINED)
        }
      }
    }
  `)

  return {
    pokerVideos: [],
    rummyVideos: rummyVideos.map((p, i) => ({
      ...p,
      thumbnail: thumbnails[`r${i + 1}`].childImageSharp.gatsbyImageData,
    })),
    carromVideos: [],
    poolVideos: [],
    gangsOfGamersVideos: [],
  }
}

export default useVideos
